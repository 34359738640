<template>
  <div>
    <a class="navbar-brand" href="/" style="margin-right: 10px;">返回WorkData</a>
    <div class="container1 container">

      <nav class="navbar navbar-expand-lg navbar-light bg-light light—group">


      </nav>
      <div class="container1">
        <div class="row">
          <!-- <div class="col-md-2">
          <img :src="items.logo" alt="软件logo" style="max-width:100%;  max-height: 140px;" id="head" />
        </div> -->
          <div class="col-md-10" style="margin-top: 0px; margin-bottom: 0px;">
            <h1 style="color:blue;" id="hname">{{ share_data[0].a_name }}</h1>
            <div style="color:grey;" id="desc">{{ items.desc }}</div>
            <div class="container1">
              <button class="btn btn-primary link1" :id="if_index === index ? '' : 'link2'" @click="toPage(index)"
                v-for="(item, index) in share_data[0].album" :key="index">
                {{ item.hname }}
              </button>
              <button class="btn btn-primary link1 link-group" @click="DB_bu()">
                聚合分享
              </button>
            </div>
          </div>
        </div>
        <p style="margin-top: 10px;">以下内容可能存在广告、营销等内容，并不代表workdata自身观点，请注意辨别</p>
        <div class="row" v-for="(item, index) in share_data[0].album" :key="index" v-if="if_index == index">
          <div class="col-md-12" v-if="!item.is_iframe">
            <!-- <h2 style="font-size: 1.5em;"></h2> -->
            <Iframe :link='item.link' :if_Iframe="if_Iframe"></Iframe>
          </div>
          <div class="prompt" v-else>当前应用暂不支持半屏打开，请点击 <a :href="item.link" target="_blank"> 新窗口</a> 打开</div>
          <div class="prompt">
            <router-link :to="{ path: '/about' }">提意见</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?181b2d56cf41a8d4e9262a313c2bacbc";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
</script>
  <script>
  import cloudbase from "@cloudbase/js-sdk";
  import Iframe from '../../components/iframe.vue'
  export default {
    name: "Index",
    components: {
      Iframe,
    },
    data() {
      return {
        items: [],
        id: "",
        info: {},
        if_Iframe:true,
        share_data:'',
        if_index:0,
        setting: {
          title: "111",
          keywords: "111",
          description: "111",
        },
        title_name:''
      };
    },
    metaInfo() {
    return {
      title:this.title_name,
      meta: [
        { name: "keywords", content: this.setting.keywords },
        { name: "description", content: this.setting.description },
      ],
    };
  },
    created() {
      this.id = this.$route.query._id;
      this.share()
    },
    methods: {
      if_iframe(){
        if (this.items.is_iframe) {
          console.log(this.items.is_iframe);
          this.if_Iframe = false
        }else{
          console.log(this.items.is_iframe);
          this.if_Iframe = true
  
        }
        
      },
      DB_bu() {

        let ID = this.id
        var aux = document.createElement("input");
        aux.setAttribute("value", "https://workdata.yijianbox.com/#/group?_id=" + ID);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        this.$message({
          message: '复制分享链接成功',
          type: 'success'
        });
        // this.$message('');
        // alert("");
      },
      toPage(index) {
       this.if_index = index
      },
      share(){ 
       let _id = this.$route.query._id;
      console.log(_id);
      let self = this;
      const app = cloudbase.init({
        env: "feedbox-8gkm7lata4626018", // 此处填入您的环境ID
      });
      app
        .auth()
        .anonymousAuthProvider()
        .signIn()
        .then(() => {
          //huoqyu bid
          app
            .callFunction({
              // 云函数名称
              name: "hao",
              // 传给云函数的参数
              data: {
                type: "get_album_detail",
                _id:_id
              },
            })
            .then((res) => {
             console.log(res);
              self.share_data = res.result.list
              console.log(self.share_data[0].album[0].link);
              self.title_name = res.result.list[0].a_name
              // console.log(res.result.list[0].a_name,'this.title_name');
            });
        });
    },
    if_iframe(){
      console.log(this.share_data);
      if (this.share_data.is_iframe) {
        console.log(this.items.is_iframe);
        this.if_Iframe = false
      }else{
        console.log(this.items.is_iframe);
        this.if_Iframe = true

      }
      
    },
    },
  };
  </script>
  
<style scoped>
.container1 {
  width: 1300px;
}

.detail-container {
  width: 100%;
}

#tag1 {
  margin-left: 0px;
}

.detail_tag {
  color: grey;
  font-size: 1.2em;
  margin: 10px;
  display: inline-block;
  font-size: 12px;
}

#link2 {
  background-color: #ffffff;
  color: black;
  border: 1px solid;
  /* margin-bottom: 0px; */
}

.link1 {
  margin-bottom: 0px;

}

#desc {
  font-size: 1.2em;
}

.navbar-brand {
  font-weight: 900
}

.col-md-2 {
  height: 2px;
  display: flex;
  /* 主轴居中 */
  justify-content: center;
  /* 从轴居中 */
  align-items: center;
  margin-top: 100px;
}

#head {
  border-radius: 8px;
}

.light—group {
  margin-bottom: 0px;
}

#hname {
  margin: 0;
  margin-bottom: 10px;
}

.link-group {
  float: right;
}

.but-box {
  width: 100%;
}

.prompt {
  width: 100%;
  height: 70px;
  text-align: center;
  line-height: 70px;
  font-size: 15px;
}

.prompt a {
  color: blue;
}
</style>